import { useState } from "react";
import TrackVisibility from 'react-on-screen';

export const RegisterAffiliate = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    preferredProgram: '',
    interest: ''
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Register');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Registering...");
    
    const formData = new FormData();
    formData.append('firstName', formDetails.firstName);
    formData.append('lastName', formDetails.lastName);
    formData.append('email', formDetails.email);
    formData.append('phone', formDetails.phone);
    formData.append('preferredProgram', formDetails.preferredProgram);
    formData.append('interest', formDetails.interest);

    let response = await fetch('https://formsubmit.co/ajax/jobs@perspective.et', {
      method: 'POST',
      body: formData,
    });

    setButtonText("Register");
    setFormDetails(formInitialDetails);

    if (response.ok) {
      setStatus({ success: true, message: 'Registration successful! You will be contacted soon.' });
    } else {
      setStatus({ success: false, message: 'Something went wrong, please try again later.' });
    }
  };

  return (
    <section className="py-16 md:py-24" id="connect">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2 className="register-affiliate">Register as an Affiliate</h2>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full sm:w-1/2 px-2 mb-4">
                        <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} className="w-full bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300" />
                      </div>
                      <div className="w-full sm:w-1/2 px-2 mb-4">
                        <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} className="w-full bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300" />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-2">
                      <div className="w-full sm:w-1/2 px-2 mb-4">
                        <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} className="w-full bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300" />
                      </div>
                      <div className="w-full sm:w-1/2 px-2 mb-4">
                        <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)} className="w-full bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300" />
                      </div>
                    </div>
                    <div className="mb-4">
                      <select value={formDetails.preferredProgram} onChange={(e) => onFormUpdate('preferredProgram', e.target.value)} className="w-full bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300">
                        <option value="">Select Preferred Program</option>
                        <option value="services">Services Commission Program</option>
                        <option value="template">Readymade Website Shop Commission Program</option>
                        <option value="both">Both Programs</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <textarea rows="6" value={formDetails.interest} placeholder="Why Are You Interested in This Program?" onChange={(e) => onFormUpdate('interest', e.target.value)} className="w-full bg-transparent border border-gray-400 rounded-md py-3 px-4 text-gray-300 focus:outline-none focus:ring-1 focus:ring-pink-300 resize-none"></textarea>
                    </div>
                    <button type="submit" className="but">{buttonText}</button>
                    {status.message && (
                      <p className={`mt-4 text-center ${status.success ? "text-green-500" : "text-red-500"}`}>{status.message}</p>
                    )}
                  </form>
                </div>
              }
            </TrackVisibility>
          </div>
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2 className="bannertitile" style={{ 'fontSize': '36px' }}>REGISTER, PROMOTE & EARN!</h2>
          </div>
        </div>
      </div>
    </section>
  );
};
